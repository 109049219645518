import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse } from '../../interfaces/common';
import { baseQuery } from './common';
import { IQueue, IQueuePatientCheckInHistory, IQueuePatientsHistory, IQueueUnavailablePatients } from '../../interfaces/queue';

export const queueApi = createApi({
	reducerPath: 'queue',
	baseQuery: baseQuery,
	tagTypes: ['Queue'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getQMS: builder.query<IApiResponse<IQueue>, { departmentId: string; pagination: { page: number; limit: number } }>({
			query: ({ departmentId, pagination: { page = 1, limit = 10 } }) =>
				`/qms/queue/by-department/${departmentId}?page=${page}&limit=${limit}`,
			providesTags: (result, error, arg) => [{ type: 'Queue', id: arg.departmentId }],
		}),
		getQMSUnavailablePatients: builder.query<
			IApiResponse<{ success: string; message: string; data: IQueueUnavailablePatients[] }>,
			{ departmentId: string }
		>({
			query: ({ departmentId }) => `/qms/unavailable/${departmentId}`,
			providesTags: ['Queue'],
		}),
		getQMSHistory: builder.query<IApiResponse<{ success: string; message: string; data: IQueuePatientsHistory[] }>, { departmentId: string }>(
			{
				query: ({ departmentId }) => `/qms/history/${departmentId}`,
				providesTags: ['Queue'],
			}
		),
		getQMSCheckInHistory: builder.query<
			IApiResponse<{
				success: string;
				message: string;
				data: {
					patientInfo: {
						ticketNumber: string;
						appointmentType: string;
						civisHospitalPatientId: string;
						patientName: string;
					};
					checkInDetails: IQueuePatientCheckInHistory[];
				};
			}>,
			{ departmentId: string }
		>({
			query: ({ departmentId }) => `/qms/check-in-details/${departmentId}`,
			providesTags: (result, error, arg) => [{ type: 'Queue', id: arg.departmentId }],
		}),
		addQueue: builder.mutation<IQueue, Partial<IQueue>>({
			query: (body) => ({
				url: `queue`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Queue'],
		}),
		moveNextToServing: builder.mutation<IQueue, { appointmentId: string }>({
			query(data) {
				return {
					url: `/qms/serve/${data.appointmentId}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: ['Queue'],
		}),
		markAsUnavailable: builder.mutation<IQueue, { appointmentId: string }>({
			query(data) {
				return {
					url: `/qms/unavailable/${data.appointmentId}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: ['Queue'],
		}),
		callPatient: builder.mutation<IQueue, { appointmentId: string }>({
			query(data) {
				return {
					url: `/qms/call/${data.appointmentId}`,
					method: 'POST',
					body: data,
				};
			},
			invalidatesTags: ['Queue'],
		}),
		transferQmsPatient: builder.mutation<IQueue, { appointmentId: string; nextDepartmentId: string }>({
			query(data) {
				return {
					url: `/qms/transfer`,
					method: 'POST',
					body: data,
				};
			},
			invalidatesTags: ['Queue'],
		}),
		reinstateQMSPatient: builder.mutation<IQueue, { appointmentId: string }>({
			query(data) {
				return {
					url: `/qms/reinstate/${data.appointmentId}`,
					method: 'PATCH',
				};
			},
			invalidatesTags: ['Queue'],
		}),
	}),
});

export const {
	useGetQMSQuery,
	useGetQMSUnavailablePatientsQuery,
	useGetQMSHistoryQuery,
	useGetQMSCheckInHistoryQuery,
	useAddQueueMutation,
	useMoveNextToServingMutation,
	useMarkAsUnavailableMutation,
	useCallPatientMutation,
	useTransferQmsPatientMutation,
	useReinstateQMSPatientMutation,
} = queueApi;
